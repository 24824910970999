<template>
  <b-modal id="add-unit-modal" scrollable size="lg">
    <template v-slot:modal-header>
      <b-container>
        <b-row>
          <b-col>
            <h3>{{ trans('units.add_unit_' + projectType) }}</h3>
            <p class="description">
              {{ trans('units.add_unit_' + projectType + '_description') }}
            </p>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <template v-slot:default>
      <b-container>
        <b-row>
          <b-col>
            <b-form class="margin-bottom-16" @submit.prevent>
              <b-form-group id="unit_name_group" :label="trans('global.name')" label-for="unit_name">
                <b-form-input
                  id="unit_name"
                  v-model="$v.unitForm.name.$model"
                  name="unit_name"
                  class="form-control-lg"
                  :state="formErrors.name"
                  type="text"
                  :placeholder="trans('projects.name_placeholder')"
                  aria-describedby="name-msg"
                  trim
                ></b-form-input>
                <b-form-invalid-feedback id="name-msg">
                  <span v-if="!$v.unitForm.name.required">{{ trans('units.form_add_new_errors.name') }}</span>
                  <span v-if="!$v.unitForm.name.minlength">{{ trans('units.form_add_new_errors.name') }}</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="unit_type_group" :label="trans('units.unit_type')" label-for="unit_type">
                <b-form-select
                  id="unit_type"
                  v-model="$v.unitForm.type.$model"
                  name="unit_type"
                  size="lg"
                  class="form-control-lg"
                  :state="formErrors.type"
                  aria-describedby="type-msg"
                >
                  <option :value="null">
                    {{ trans('units.unit_type_desc') }}
                  </option>
                  <option value="1">
                    {{ trans('global.cabin') }}
                  </option>
                  <option value="2">
                    {{ trans('global.apartment') }}
                  </option>
                  <option value="3">
                    {{ trans('global.house') }}
                  </option>
                  <option value="4">
                    {{ trans('global.industry') }}
                  </option>
                  <option value="5">
                    {{ trans('global.unit') }}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback id="type-msg">
                  <span v-if="!$v.unitForm.type.required">{{ trans('units.form_add_new_errors.type') }}</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <!--              <b-form-group id="unit_salestext_group" :label="trans('units.form_add_new.salestext')" label-for="unit_salestext">-->
              <!--                <b-form-textarea id="unit_salestext" name="unit_salestext" class="form-control-lg" v-model="unitForm.salestext" :placeholder="trans('units.form_add_new.salestext_placeholder')" rows="3" max-rows="6"></b-form-textarea>-->
              <!--              </b-form-group>-->
              <!--              <b-form-group id="unit_details_group" :label="trans('units.form_add_new.details')" label-for="unit_details">-->
              <!--                <b-form-textarea id="unit_details" name="unit_details" class="form-control-lg" v-model="unitForm.details" :placeholder="trans('units.form_add_new.details_placeholder')" rows="3" max-rows="6"></b-form-textarea>-->
              <!--              </b-form-group>-->
              <!--              <b-form-group id="unit_contactinfo_group" :label="trans('units.form_add_new.contactinfo')" label-for="unit_contactinfo">-->
              <!--                <b-form-textarea id="unit_contactinfo" name="unit_contactinfo" class="form-control-lg" v-model="unitForm.contactInformation" :placeholder="trans('units.form_add_new.contactinfo_placeholder')" rows="3" max-rows="6"></b-form-textarea>-->
              <!--              </b-form-group>-->
              <!--              <b-form-group id="unit_description_group" :label="trans('units.form_add_new.description')" label-for="unit_description">-->
              <!--                <b-form-textarea id="unit_description" name="unit_description" class="form-control-lg" v-model="unitForm.description" :placeholder="trans('units.form_add_new.description_placeholder')" rows="3" max-rows="6"></b-form-textarea>-->
              <!--              </b-form-group>-->
            </b-form>
          </b-col>
        </b-row>
      </b-container>
    </template>

    <template v-slot:modal-footer="{ cancel }">
      <b-button variant="light" size="md" class="padding-left-60 padding-right-60" @click="cancel()">
        {{ trans('global.cancel') }}
      </b-button>
      <b-button
        ref="submitbtn"
        variant="primary"
        size="md"
        :style="{ width: submitbtnwidth ? submitbtnwidth : 'auto' }"
        class="padding-left-60 padding-right-60"
        @click="createUnit"
      >
        <span v-if="!loading">{{ trans('units.save_unit_' + projectType) }}</span>
        <b-spinner v-else variant="white"></b-spinner>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
  /*
    Project-and-unit-types (hardcoded for now):
    1. Hytter
    2. Leiligheter
    3. Hus
    4. Næring
    5. Annet

    Room-types (hardcoded for now):
    1. Kjøkken
    2. Kjøkken/Stue
    3. Stue
    4. Soverom
    5. Bad
    6. WC
    7. Vaskerom
    8. WC/Vaskerom
   */

  import { mapState } from 'vuex'
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'

  const fb = require('../../../../firebaseConfig')

  export default {
    name: 'ModalAddUnit',
    mixins: [validationMixin],
    data () {
      return {
        loading: false,
        unitForm: {
          name: '',
          salestext: '',
          details: '',
          contactInformation: '',
          type: null,
          description: ''
        },
        projectTypes: {
          1: 'cabin',
          2: 'apartment',
          3: 'house',
          4: 'industry',
          5: 'unit'
        },
        submitbtnwidth: null
      }
    },
    validations () {
      return {
        unitForm: {
          name: {
            required
          },
          type: {
            required
          }
        }
      }
    },
    computed: {
      ...mapState({
        userProfile: state => state.auth.userProfile,
        projects: state => state.projects.projects
      }),
      project () {
        return this.projects.find(project => project.slug === this.$route.params.projectslug)
      },
      formErrors () {
        return {
          name: (this.$v.unitForm.name.$dirty && this.submitted) ? !this.$v.unitForm.name.$error : null,
          type: (this.$v.unitForm.type.$dirty && this.submitted) ? !this.$v.unitForm.type.$error : null
        }
      },
      projectType () {
        return this.project !== undefined ? this.projectTypes[this.project.type] : 'loading'
      }
    },
    mounted () {
      this.unitForm.type = this.project !== undefined ? this.project.type : null
    },
    methods: {
      createUnit () {
        this.submitbtnwidth = this.$refs.submitbtn ? this.$refs.submitbtn.clientWidth : 'auto'
        this.loading = true
        this.submitted = true

        this.$v.unitForm.$touch()

        if (this.$v.unitForm.$anyError) {
          this.loading = false
          return
        }

        const slug = this.createSlug(this.unitForm.name, 'units/slugHelper', this.project._id)
        const varycode = this.doPaddy(this.$store.state.configs.varyCode)

        fb.varycodesCollection.doc(varycode).set({
          createdOn: new Date(),
          varycode,
          userId: this.userProfile.uid,
          type: 2 // 1 = project, 2 = unit, 3 = prospect
        }).then(() => {
          fb.unitsCollection.add({
            createdOn: new Date(),
            name: this.unitForm.name,
            salestext: this.unitForm.salestext,
            details: this.unitForm.details,
            contactinfo: this.unitForm.contactInformation,
            slug,
            description: this.unitForm.description,
            userId: this.userProfile.uid,
            projectId: this.project._id,
            varycode,
            type: this.unitForm.type
          }).then((res) => {
            if (res.id !== undefined && res.id !== '') {
              fb.varycodesCollection.doc(varycode).set({
                typeId: res.id
              }, { merge: true })
            }
            this.unitForm.name = ''
            this.unitForm.salestext = ''
            this.unitForm.details = ''
            this.unitForm.contactInformation = ''
            this.unitForm.description = ''
            this.unitForm.type = this.project.type
            this.submitted = false
            this.loading = false
            this.hideModal('add-unit-modal')
            this.$store.commit('configs/setProjectTopbarMenu', 'units')
          }).catch((err) => {
            console.log('Could not save unit:')
            console.log(err)
          })
        })
      },
      hideModal (id) {
        this.$root.$emit('bv::hide::modal', id)
      }
    }
  }
</script>
